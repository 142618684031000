import { useTaskContext } from '../hooks/useTaskContext'
import TaskItem from './TaskItem'
import NewTaskInput from './NewTaskInput'
import TimeTotal from './TimeTotal'

const TaskList = ({ onEditTask, onDragStart, onDragOver, onDragLeave, onDrop }) => {
    const { tasks } = useTaskContext()

    return (
        <div className="tasks-list">
            <div className="column-header">
                <h2>Tasks</h2>
                {tasks && <TimeTotal tasks={tasks} />}
            </div>
            <div 
                className="tasks-list-content"
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={(e) => onDrop(e, 'task-list')}
            >
                {tasks && tasks.map((task, index) => (
                    <TaskItem 
                        key={task._id}
                        task={task}
                        index={index}
                        onEdit={onEditTask}
                        onDragStart={onDragStart}
                    />
                ))}
            </div>
            <NewTaskInput />
        </div>
    )
}

export default TaskList 