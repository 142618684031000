import { useState, useRef } from 'react'
import TaskItem from './TaskItem'
import TimeTotal from './TimeTotal'
import TimerPopup from './TimerPopup'
import CheckIn from './CheckIn'
import TaskEditDialog from './TaskEditDialog'
import { useAuthContext } from '../hooks/useAuthContext'
import { useTaskContext } from '../hooks/useTaskContext'

const FocusBlock = ({ id, onRemove, tasks = [], onDragStart, onDragOver, onDragLeave, onDrop }) => {
    const [title, setTitle] = useState(`Focus Block ${id}`)
    const [isEditing, setIsEditing] = useState(false)
    const [showCheckIn, setShowCheckIn] = useState(false)
    const [editingTask, setEditingTask] = useState(null)
    const timerRef = useRef(null)
    const { user } = useAuthContext()
    const { dispatch } = useTaskContext()

    const handleTitleClick = () => {
        setIsEditing(true)
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleTitleBlur = () => {
        setIsEditing(false)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false)
        }
    }

    // Get the first uncompleted task
    const currentTask = tasks.find(task => task.status !== 'done')

    const handleTimerComplete = () => {
        setShowCheckIn(true)
    }

    const handleContinueTask = async () => {
        try {
            // Update UI immediately with new actual time
            const updatedTask = { 
                ...currentTask, 
                actualTime: (currentTask.actualTime || 0) + 5 // 5 minutes chunk
            }
            dispatch({ type: 'UPDATE_TASK', payload: updatedTask })
            
            // Update backend
            const response = await fetch(`/api/tasks/${currentTask._id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    actualTime: updatedTask.actualTime
                })
            })

            if (!response.ok) {
                // If backend update fails, revert the UI change
                dispatch({ type: 'UPDATE_TASK', payload: currentTask })
                console.error('Failed to update task time')
                return
            }

            // Update the task in the focus block
            const updatedTasks = tasks.map(t => 
                t._id === updatedTask._id ? updatedTask : t
            )
            onDrop(null, `focus-block-${id}`, updatedTasks)

            // Close check-in and restart timer
            setShowCheckIn(false)
            if (timerRef.current) {
                timerRef.current.startTimer()
            }
        } catch (error) {
            console.error('Error updating task time:', error)
            dispatch({ type: 'UPDATE_TASK', payload: currentTask })
        }
    }

    const handleCompleteTask = async () => {
        // Store current task reference before any state changes
        const taskToUpdate = currentTask

        // Immediately close the dialog
        setShowCheckIn(false)

        try {
            // Update UI immediately with completion status and new actual time
            const updatedTask = { 
                ...taskToUpdate, 
                status: 'done',
                actualTime: (taskToUpdate.actualTime || 0) + 5 // 5 minutes chunk
            }

            // Update the task in the focus block immediately
            const updatedTasks = tasks.map(t => 
                t._id === updatedTask._id ? updatedTask : t
            )
            onDrop(null, `focus-block-${id}`, updatedTasks)

            // Then update the global state
            dispatch({ type: 'UPDATE_TASK', payload: updatedTask })

            // Get the next uncompleted task
            const nextTask = tasks.find(task => task.status !== 'done' && task._id !== taskToUpdate._id)
            
            // If there's a next task, start its timer immediately
            if (nextTask && timerRef.current) {
                timerRef.current.startTimer()
            }

            // Update backend
            const response = await fetch(`/api/tasks/${taskToUpdate._id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    status: 'done',
                    actualTime: updatedTask.actualTime
                })
            })

            if (!response.ok) {
                // If backend update fails, revert the UI changes
                const revertedTask = { ...taskToUpdate }
                dispatch({ type: 'UPDATE_TASK', payload: revertedTask })
                const revertedTasks = tasks.map(t => 
                    t._id === taskToUpdate._id ? revertedTask : t
                )
                onDrop(null, `focus-block-${id}`, revertedTasks)
                console.error('Failed to update task status')
                return
            }
        } catch (error) {
            console.error('Error completing task:', error)
            // Revert UI changes on error
            const revertedTask = { ...taskToUpdate }
            dispatch({ type: 'UPDATE_TASK', payload: revertedTask })
            const revertedTasks = tasks.map(t => 
                t._id === taskToUpdate._id ? revertedTask : t
            )
            onDrop(null, `focus-block-${id}`, revertedTasks)
        }
    }

    const handleDistraction = (distractionType) => {
        console.log('Distracted by:', distractionType)
        setShowCheckIn(false)
        if (timerRef.current) {
            timerRef.current.startTimer()
        }
    }

    const handleEditTask = (task) => {
        setEditingTask(task)
    }

    const handleTaskUpdate = (updatedTask) => {
        // Update the task in the current focus block
        const updatedTasks = tasks.map(t => 
            t._id === updatedTask._id ? updatedTask : t
        )
        // Update the blockTasks state through the parent component
        onDrop(null, `focus-block-${id}`, updatedTasks)
    }

    const handleTaskStatusChange = (updatedTask) => {
        // Update the task in the current focus block
        const updatedTasks = tasks.map(t => 
            t._id === updatedTask._id ? updatedTask : t
        )
        // Update the blockTasks state through the parent component
        onDrop(null, `focus-block-${id}`, updatedTasks)
    }

    const handleTaskDelete = (deletedTask) => {
        // Remove the task from the current focus block
        const updatedTasks = tasks.filter(t => t._id !== deletedTask._id)
        // Update the blockTasks state through the parent component
        onDrop(null, `focus-block-${id}`, updatedTasks)
    }

    return (
        <div className="focus-block">
            <div className="focus-block-header">
                {isEditing ? (
                    <input
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        className="focus-block-title-input"
                    />
                ) : (
                    <div className="focus-block-title-row">
                        <h2 
                            className="focus-block-title"
                            onClick={handleTitleClick}
                        >
                            {title}
                        </h2>
                        <TimeTotal tasks={tasks} />
                    </div>
                )}
                {id !== 1 && (
                    <button 
                        className="remove-focus-block"
                        onClick={() => onRemove(id)}
                    >
                        ×
                    </button>
                )}
            </div>

            <div className="focus-block-timer">
                <button 
                    className="start-button"
                    onClick={() => timerRef.current?.startTimer()}
                    disabled={!currentTask}
                >
                    Let's fucking go!
                </button>
            </div>

            <div 
                className="focus-block-content"
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={(e) => onDrop(e, `focus-block-${id}`)}
            >
                {tasks.map((task, index) => (
                    <TaskItem
                        key={task._id}
                        task={task}
                        index={index}
                        onDragStart={onDragStart}
                        onEdit={handleEditTask}
                        onDelete={handleTaskDelete}
                        onStatusChange={handleTaskStatusChange}
                    />
                ))}
            </div>

            <TimerPopup 
                ref={timerRef}
                task={currentTask}
                onTimerComplete={handleTimerComplete}
            />

            {showCheckIn && currentTask && currentTask.status !== 'done' && (
                <CheckIn
                    task={currentTask}
                    onComplete={handleCompleteTask}
                    onContinue={handleContinueTask}
                    onDistraction={handleDistraction}
                />
            )}

            {editingTask && (
                <TaskEditDialog
                    task={editingTask}
                    isOpen={true}
                    onClose={() => setEditingTask(null)}
                    onTaskUpdate={handleTaskUpdate}
                />
            )}
        </div>
    )
}

export default FocusBlock 