import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, error, isLoading} = useLogin()

    const handleSubmit = async (e) => {
        e.preventDefault()
        await login(email, password)
    }

    return (
        <form className="login" onSubmit={handleSubmit}>
            <h3 className="text-2xl font-bold mb-4 text-center">Login</h3>
            <label>Email:</label>
            <input type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Email" 
                required
            />  
            <label>Password:</label>
            <input type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Password" 
                required
            />
            <button type="submit" disabled={isLoading}>Login</button>
            {error && <div className="error">{error}</div>}
        </form>
    )
}


export default Login