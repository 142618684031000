import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { Coffee, Power } from 'lucide-react'

const TimerPopup = forwardRef(({ task, onTimerComplete }, ref) => {
    const TIMER_DURATION = 300 // 5 minutes in seconds
    const [timeLeft, setTimeLeft] = useState(TIMER_DURATION)
    const [isRunning, setIsRunning] = useState(false)
    const { user } = useAuthContext()
    const [wakeLock, setWakeLock] = useState(null)
    const [isWakeLockActive, setIsWakeLockActive] = useState(false)

    const requestWakeLock = async () => {
        try {
            if ('wakeLock' in navigator) {
                const lock = await navigator.wakeLock.request('screen')
                setWakeLock(lock)
                setIsWakeLockActive(true)

                lock.addEventListener('release', () => {
                    setIsWakeLockActive(false)
                    setWakeLock(null)
                })
            }
        } catch (err) {
            console.error('Wake Lock error:', err)
            setIsWakeLockActive(false)
        }
    }

    const releaseWakeLock = async () => {
        if (wakeLock) {
            await wakeLock.release()
            setWakeLock(null)
            setIsWakeLockActive(false)
        }
    }

    useEffect(() => {
        let interval = null
        if (isRunning && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(time => time - 1)
            }, 1000)
        } else if (timeLeft === 0) {
            setIsRunning(false)
            releaseWakeLock()
            onTimerComplete()
        }

        return () => {
            clearInterval(interval)
            releaseWakeLock()
        }
    }, [isRunning, timeLeft, onTimerComplete])

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60)
        const secs = seconds % 60
        return `${mins}:${secs.toString().padStart(2, '0')}`
    }

    const startTimer = () => {
        // Reset all timer state
        setIsRunning(false)
        setTimeLeft(TIMER_DURATION)
        // Use requestAnimationFrame to ensure state updates are processed
        requestAnimationFrame(async () => {
            setIsRunning(true)
            await requestWakeLock()
        })
    }

    // Calculate circle timer progress
    const radius = 100
    const circumference = 2 * Math.PI * radius
    const progress = timeLeft / TIMER_DURATION
    const strokeDashoffset = circumference * (1 - progress)

    // Expose startTimer method to parent
    useImperativeHandle(ref, () => ({
        startTimer
    }))

    if (!isRunning) return null

    return (
        <div className="timer-popup-overlay">
            <div className="timer-popup">
                <div className="timer-popup-header">
                    <h1>Grinding on:</h1>
                </div>

                <div className="timer-popup-task">
                    <h2>{task?.name || 'No task selected'}</h2>
                </div>

                <div className="timer-popup-circle">
                    <svg width="220" height="220" viewBox="0 0 220 220">
                        {/* Background circle */}
                        <circle
                            cx="110"
                            cy="110"
                            r={radius}
                            fill="none"
                            stroke="#eee"
                            strokeWidth="10"
                        />
                        {/* Progress circle */}
                        <circle
                            cx="110"
                            cy="110"
                            r={radius}
                            fill="none"
                            stroke="var(--primary)"
                            strokeWidth="10"
                            strokeDasharray={circumference}
                            strokeDashoffset={strokeDashoffset}
                            transform="rotate(-90 110 110)"
                            style={{
                                transition: 'stroke-dashoffset 0.3s ease'
                            }}
                        />
                    </svg>
                </div>

                <div className="timer-popup-time">
                    <span className="time-display">{formatTime(timeLeft)}</span>
                </div>
            </div>
        </div>
    )
})

export default TimerPopup 