import React from 'react';
import { Clock, Brain, BarChart, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Hero Section */}
      <header className="container mx-auto px-4 py-16">
        <div className="text-center">
          <h1 className="text-5xl font-bold text-green-800 mb-4">
            Snap Into Focus ⚡
          </h1>
          <p className="text-xl text-green-700 mb-8">
            The task manager that gets your ADHD brain and wants to be BFFs with your dopamine.
          </p>
        </div>
      </header>

      {/* Features Section */}
      <section className="container mx-auto px-4 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          {/* 5-min Check-ins */}
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <div className="text-green-500 mb-4">
              <Clock size={48} />
            </div>
            <h3 className="text-2xl font-bold text-green-800 mb-4">
              5-Minute Check-ins
            </h3>
            <p className="text-green-700">
              Like a friendly tap on the shoulder asking "Hey, still working on that thing?" Because we know that Reddit rabbit hole is mighty tempting.
            </p>
          </div>

          {/* Time Tracking */}
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <div className="text-green-500 mb-4">
              <Brain size={48} />
            </div>
            <h3 className="text-2xl font-bold text-green-800 mb-4">
              5-Minute Time Tracking
            </h3>
            <p className="text-green-700">
              Track your time in bite-sized chunks. Because "I'll just check Twitter real quick" is actually a 45-minute adventure.
            </p>
          </div>

          {/* Time Analysis */}
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <div className="text-green-500 mb-4">
              <BarChart size={48} />
            </div>
            <h3 className="text-2xl font-bold text-green-800 mb-4">
              Reality Check Analytics
            </h3>
            <p className="text-green-700">
              Compare estimated vs. actual time spent. Spoiler alert: That "quick" email took 3 hours. We'll help you get better at guessing!
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="container mx-auto px-4 py-16 text-center">
        <div className="bg-green-100 rounded-xl p-12">
          <h2 className="text-3xl font-bold text-green-800 mb-6">
            Ready to Finally Get That Thing Done?
          </h2>
          <p className="text-xl text-green-700 mb-8">
            Join thousands of others who've discovered that productivity can be fun-ish. 
            No more shame spirals, just progress (and the occasional productive hyperfocus).
          </p>
          <div className="space-x-4">
            {/* <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-lg transition-colors duration-200">
              Start Free Trial
            </button>
            <button className="border-2 border-green-500 hover:bg-green-50 text-green-500 font-bold py-3 px-8 rounded-lg transition-colors duration-200">
              Watch Demo
            </button> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-8 text-center text-green-700">
          <p>© 2025 Snap Into Focus - Because your brain deserves a high-five 🖐️</p>
          <p>Made with ❤️ by <a href="https://prisacaru.com" target="_blank" rel="noopener noreferrer">Prisacaru</a></p>
      </footer>
      <p className="text-xs text-gray-300 text-right">Version 1.0.41</p>
    </div>
  );
};
export default LandingPage;
