import { useEffect, useState } from 'react'
import { useTaskContext } from '../hooks/useTaskContext'
import { useAuthContext } from '../hooks/useAuthContext'
import TaskList from '../components/TaskList'
import TaskEditDialog from '../components/TaskEditDialog'
import FocusBlock from '../components/FocusBlock'

const Dashboard = () => {
    const { tasks, dispatch } = useTaskContext()
    const { user } = useAuthContext()
    const [editingTask, setEditingTask] = useState(null)
    const [focusBlocks, setFocusBlocks] = useState([1]) // Start with one focus block
    const [blockTasks, setBlockTasks] = useState({}) // Tasks assigned to each focus block
    
    useEffect(() => {
        const fetchTasks = async () => {
            try {
                if (!user || !user.token) {
                    console.log('No user or token available')
                    return
                }

                const response = await fetch('/api/tasks', {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                })
                const json = await response.json()

                if (response.ok) {
                    // Filter tasks into their respective blocks
                    const tasksByBlock = {}
                    const mainTasks = []

                    json.forEach(task => {
                        if (task.blockId === 0) {
                            mainTasks.push(task)
                        } else if (task.blockId > 0) {
                            const blockKey = `focus-block-${task.blockId}`
                            if (!tasksByBlock[blockKey]) {
                                tasksByBlock[blockKey] = []
                            }
                            tasksByBlock[blockKey].push(task)
                        }
                        // Ignore tasks with blockId -1 (archived)
                    })

                    // Update state
                    dispatch({type: 'SET_TASKS', payload: mainTasks})
                    setBlockTasks(tasksByBlock)

                    // Update focus blocks based on existing tasks
                    const existingBlockIds = Object.keys(tasksByBlock)
                        .map(key => parseInt(key.split('-').pop()))
                        .filter(id => !isNaN(id))
                    
                    if (existingBlockIds.length > 0) {
                        setFocusBlocks([...new Set(existingBlockIds)].sort((a, b) => a - b))
                    }
                } else {
                    console.error('Failed to fetch tasks:', json.error)
                }
            } catch (error) {
                console.error('Error fetching tasks:', error)
            }
        }

        fetchTasks()
    }, [dispatch, user])

    // Add this effect to handle task updates
    useEffect(() => {
        if (tasks) {
            // When tasks are updated, refresh the block tasks
            const tasksByBlock = {}
            const allTasks = [...tasks] // Main task list

            // Add tasks from focus blocks
            Object.values(blockTasks).forEach(blockTaskList => {
                allTasks.push(...blockTaskList)
            })

            // Redistribute tasks based on their blockId
            allTasks.forEach(task => {
                if (task.blockId > 0) {
                    const blockKey = `focus-block-${task.blockId}`
                    if (!tasksByBlock[blockKey]) {
                        tasksByBlock[blockKey] = []
                    }
                    tasksByBlock[blockKey].push(task)
                }
            })

            setBlockTasks(tasksByBlock)
        }
    }, [tasks])

    const addFocusBlock = () => {
        const newId = Math.max(...focusBlocks) + 1
        setFocusBlocks([...focusBlocks, newId])
    }

    const removeFocusBlock = async (id) => {
        const blockId = `focus-block-${id}`
        const tasksToArchive = blockTasks[blockId] || []
        
        // Move tasks to archive (blockId = -1)
        for (const task of tasksToArchive) {
            try {
                await fetch('/api/tasks/' + task._id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify({ blockId: -1 })
                })
            } catch (error) {
                console.error('Error archiving task:', error)
            }
        }

        // Remove block from state
        const updatedBlockTasks = { ...blockTasks }
        delete updatedBlockTasks[blockId]
        setBlockTasks(updatedBlockTasks)
        setFocusBlocks(focusBlocks.filter(blockId => blockId !== id))
    }

    const handleDragStart = (e, taskId) => {
        e.dataTransfer.setData('text/plain', taskId)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        e.currentTarget.classList.add('drag-over')
    }

    const handleDragLeave = (e) => {
        e.currentTarget.classList.remove('drag-over')
    }

    const handleDrop = async (e, containerId, updatedTasks = null) => {
        if (e) {
            e.preventDefault()
            e.currentTarget.classList.remove('drag-over')
        }

        // If we have updatedTasks, use those instead of handling a drag event
        if (updatedTasks) {
            const newBlockTasks = { ...blockTasks }
            newBlockTasks[containerId] = updatedTasks
            setBlockTasks(newBlockTasks)
            return
        }

        // Rest of the existing drag and drop logic
        const taskId = e.dataTransfer.getData('text/plain')
        
        // Find the task in all possible locations
        let task
        let sourceContainer = 'task-list'
        
        // Check focus blocks first
        for (const [blockId, tasksInBlock] of Object.entries(blockTasks)) {
            const foundTask = tasksInBlock.find(t => t._id === taskId)
            if (foundTask) {
                task = foundTask
                sourceContainer = blockId
                break
            }
        }

        // If not found in focus blocks, check main task list
        if (!task && tasks) {
            task = tasks.find(t => t._id === taskId)
        }

        if (!task) return

        // Don't do anything if dropping in the same container
        if (sourceContainer === containerId) return

        // Calculate new blockId based on containerId
        let newBlockId = 0 // Default for task-list
        if (containerId !== 'task-list') {
            // Extract number from focus-block-X format
            newBlockId = parseInt(containerId.split('-').pop())
        }

        // Update task's blockId in the backend
        try {
            const response = await fetch('/api/tasks/' + taskId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({ blockId: newBlockId })
            })

            if (!response.ok) {
                throw new Error('Failed to update task block')
            }

            const updatedTask = await response.json()

            const newBlockTasks = { ...blockTasks }

            // Remove from source
            if (sourceContainer === 'task-list') {
                dispatch({ type: 'DELETE_TASK', payload: task })
            } else {
                newBlockTasks[sourceContainer] = newBlockTasks[sourceContainer].filter(t => t._id !== taskId)
            }

            // Add to destination
            if (containerId === 'task-list') {
                dispatch({ type: 'CREATE_TASK', payload: updatedTask })
            } else {
                newBlockTasks[containerId] = [...(newBlockTasks[containerId] || []), updatedTask]
            }

            setBlockTasks(newBlockTasks)
        } catch (error) {
            console.error('Error updating task block:', error)
        }
    }

    return (
        <div className="dashboard">
            <div className="dashboard-header">
                <h1>Dashboard</h1>
                <button 
                    className="add-focus-block"
                    onClick={addFocusBlock}
                >
                    + Add Focus Block
                </button>
            </div>
            <div className="dashboard-columns">
                <div className="dashboard-column task-list-column">
                    <TaskList 
                        onEditTask={setEditingTask}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    />
                </div>

                {focusBlocks.map(id => (
                    <div key={id} className="dashboard-column focus-block-column">
                        <FocusBlock 
                            id={id}
                            onRemove={removeFocusBlock}
                            tasks={blockTasks[`focus-block-${id}`] || []}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        />
                    </div>
                ))}
            </div>

            {editingTask && (
                <TaskEditDialog
                    task={editingTask}
                    isOpen={true}
                    onClose={() => setEditingTask(null)}
                />
            )}
        </div>
    )
}

export default Dashboard