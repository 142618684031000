import { useAuthContext } from './useAuthContext'
import { useTaskContext } from './useTaskContext'   
import { useNavigate } from 'react-router-dom'

export const useLogout = () => {
    const { dispatch } = useAuthContext()
    const { dispatch: taskDispatch } = useTaskContext()
    const navigate = useNavigate()
    const logout = () => {
        // remove user from local storage
        localStorage.removeItem('user')

        // dispatch logout action
        dispatch({type: 'LOGOUT'})
        taskDispatch({type: 'SET_TASKS', payload: null})
        navigate('/')
    }

    return { logout }
}