import { TaskContext } from '../context/taskContext'
import { useContext } from 'react'

export const useTaskContext = () => {
    const context = useContext(TaskContext)

    if (!context) {
        throw Error('useTaskContext must be used inside an TaskContextProvider')
    }

    // Ensure that all expected properties exist
    const requiredProperties = ['tasks'] // Add all required properties here
    requiredProperties.forEach(prop => {
        if (context[prop] === undefined) {
            throw Error(`TaskContext is missing required property: ${prop}`)
        }
    })

    return context
}