import { useState } from 'react'
import { useTaskContext } from '../hooks/useTaskContext'
import { useAuthContext } from '../hooks/useAuthContext'

const NewTaskInput = () => {
    const { dispatch } = useTaskContext()
    const { user } = useAuthContext()
    const [newTaskName, setNewTaskName] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const parseTaskNameAndTime = (taskName) => {
        // Match a number followed by 'm' at the end of the string
        const match = taskName.trim().match(/^(.+?)\s*(\d{2,})m$/)
        if (!match) {
            return {
                name: taskName,
                estimatedTime: 5 // Default time
            }
        }

        const [, nameWithoutTime, timeStr] = match
        let minutes = parseInt(timeStr, 10)
        
        // Round up to next 5 minute increment
        minutes = Math.ceil(minutes / 5) * 5

        return {
            name: nameWithoutTime.trim(),
            estimatedTime: minutes
        }
    }

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && newTaskName.trim()) {
            e.preventDefault()
            
            if (!user) {
                setError('You must be logged in')
                return
            }

            const { name, estimatedTime } = parseTaskNameAndTime(newTaskName)
            
            const task = {
                name,
                estimatedTime,
                actualTime: 0,
                status: 'todo'
            }

            const response = await fetch('/api/tasks', {
                method: 'POST',
                body: JSON.stringify(task),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setNewTaskName('')
                setError(null)
                dispatch({type: 'CREATE_TASK', payload: json})
            } else {
                setError(json.error)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!user) {
            setError('You must be logged in')
            return
        }

        const { name, estimatedTime } = parseTaskNameAndTime(newTaskName)

        const task = {
            name,
            estimatedTime,
            actualTime: 0,
            status: 'todo',
            blockId: 0  // Default to main task list
        }

        const response = await fetch('/api/tasks', {
            method: 'POST',
            body: JSON.stringify(task),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setNewTaskName('')
            setError(null)
            setEmptyFields([])
            dispatch({type: 'CREATE_TASK', payload: json})
        }
    }

    return (
        <div className="task-line new-task">
            <input
                type="text"
                value={newTaskName}
                onChange={(e) => setNewTaskName(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Add a new task and press Enter..."
                className="task-input"
            />
            {error && <div className="error">{error}</div>}
        </div>
    )
}

export default NewTaskInput 