import { useTaskContext } from '../hooks/useTaskContext'
import { useAuthContext } from '../hooks/useAuthContext'

const TaskItem = ({ task, onEdit, onDragStart, onDelete, onStatusChange }) => {
    const { dispatch } = useTaskContext()
    const { user } = useAuthContext()

    const handleStatusChange = async () => {
        const updatedStatus = task.status === 'done' ? 'todo' : 'done'
        
        // Update UI immediately
        const updatedTask = { ...task, status: updatedStatus }
        dispatch({type: 'UPDATE_TASK', payload: updatedTask})
        
        // Notify parent component
        if (onStatusChange) {
            onStatusChange(updatedTask)
        }

        // Update backend
        const response = await fetch(`/api/tasks/${task._id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                status: updatedStatus
            })
        })

        if (!response.ok) {
            // Revert UI changes if backend update fails
            const revertedTask = { ...task }
            dispatch({type: 'UPDATE_TASK', payload: revertedTask})
            if (onStatusChange) {
                onStatusChange(revertedTask)
            }
        }
    }

    const handleDelete = async (e) => {
        e.stopPropagation()
        const response = await fetch(`/api/tasks/${task._id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            dispatch({type: 'DELETE_TASK', payload: json})
            if (onDelete) {
                onDelete(task)
            }
        }
    }

    return (
        <div 
            className="task-line"
            draggable
            onDragStart={(e) => onDragStart(e, task._id)}
            onDoubleClick={() => onEdit(task)}
        >
            <input
                type="checkbox"
                checked={task.status === 'done'}
                onChange={handleStatusChange}
            />
            <span className={task.status === 'done' ? 'task-done' : ''}>
                {task.name}
            </span>
            <span className="task-time">
                {task.actualTime || 0}m/{task.estimatedTime || 5}m
            </span>
            <span className="delete-task" onClick={handleDelete}>×</span>
        </div>
    )
}

export default TaskItem 