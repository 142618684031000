import { useGoogleLogin } from '@react-oauth/google'

const GoogleLogin = () => {

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
          try {
            const response = await fetch('/api/auth/google', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                access_token: codeResponse.access_token,
                name: codeResponse.name,
                email: codeResponse.email,
              }),
              credentials: 'include' // Important for cookies
            });
    
            if (response.ok) {
              window.location.href = '/dashboard';
            } else {
              console.error('Login failed');
            }
          } catch (error) {
            console.error('Error during login:', error);
          }
        },
        onError: () => {
          console.log('Login Failed');
        }
      });
    
      const handleGoogleLogin = () => {
        login()
      }

      

    return (
        <div>
            <h1>Google Login</h1>
         <button 
              onClick={handleGoogleLogin}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-lg text-lg transition-colors duration-200"
            >
              Login with Google
            </button>
        </div>
    )
}

export default GoogleLogin