const TimeTotal = ({ tasks = [] }) => {
    const totalActual = tasks.reduce((sum, task) => sum + (task.actualTime || 0), 0)
    const totalEstimated = tasks.reduce((sum, task) => sum + (task.estimatedTime || 5), 0)

    return (
        <div className="time-total">
            {totalActual}m/{totalEstimated}m
        </div>
    )
}

export default TimeTotal 