import {Link} from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import GoogleLoginButton from './GoogleLoginButton'

const Navbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    return (
        <header>
            <div className="container">
                <Link to="/">
                    <h1>Snap Into Focus</h1>
                </Link>
                <div className="auth-links">
                    {user && (
                        <div>
                            <span>{user.email}</span>
                            <button onClick={logout}>Logout</button>
                        </div>
                    )}
                    {!user && (
                        <>
                            {/* <Link to="/login">Login</Link>
                            <Link to="/signup">Signup</Link> */}
                            <GoogleLoginButton />
                        </>
                    )}
                </div>
            </div>
        </header>
    )
}

export default Navbar