import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
//pages & components
import Dashboard from './pages/Dashboard'
import Navbar from './components/Navbar'
import Login from './pages/Login'
import Signup from './pages/Signup'
import LandingPage from './pages/Landingpage'
import GoogleLogin from './pages/GoogleLogin'
function App() {
  const clientId = "145045303682-ttvq4dtms8qe9sshpod5nr1shpsliach.apps.googleusercontent.com"
  console.log('client id is: ')
  console.log(clientId);
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <AuthContextProvider>
          <div className="App">
            <Navbar />
            <div className="pages">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/googleLogin" element={<GoogleLogin />} />

              </Routes>
            </div>
          </div>
        </AuthContextProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
