import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { useGoogleLogin } from '../hooks/useGoogleLogin'
//todo: get client id from env
const clientId = "145045303682-ttvq4dtms8qe9sshpod5nr1shpsliach.apps.googleusercontent.com"


const GoogleLoginButton = () => {

    const { login, isLoading, error } = useGoogleLogin()

    const handleGoogleLogin = (response) => {
        login(response)
    }
    
    const handleGoogleLoginError = (error) => {
        console.log(error)
    }

    
    return (
        <div>
            <GoogleLogin clientId={clientId} 
            onSuccess={handleGoogleLogin}
            onError={handleGoogleLoginError}
            buttonText="Login with Google"
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
            />
        </div>
    )
}

export default GoogleLoginButton