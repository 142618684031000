import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

export const useGoogleLogin = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()
    const navigate = useNavigate()

    const login = async (googleApiResponse) => {
        const { credential } = googleApiResponse;
        const decodedToken = jwtDecode(credential)
        const { email, name, picture, googleId } = decodedToken
        
        console.log(googleApiResponse)
        console.log(email, name, picture)

        const user = { email, name, picture }
        setIsLoading(true)
        setError(null)

        const response = await fetch('/api/user/googleLogin', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email, name, picture, googleId})
        })

        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            setIsLoading(false)
            setError(null)

            // save the user to local storage
            localStorage.setItem('user', JSON.stringify(json))

            // update the auth context
            dispatch({ type: 'LOGIN', payload: json })

            // redirect to the dashboard
            navigate('/dashboard')
        }
    }

    return { login, isLoading, error }
}