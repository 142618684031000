import { useState, useEffect, useCallback } from 'react'

const DISTRACTIONS = [
    { id: 1, name: 'WhatsApp' },
    { id: 2, name: 'Food' },
    { id: 3, name: 'Instagram' },
    { id: 4, name: 'Browsing' },
    { id: 5, name: 'Other' }
]

const CheckIn = ({ task, onComplete, onContinue, onDistraction }) => {
    const [audioContext] = useState(new (window.AudioContext || window.webkitAudioContext)())
    const [beepInterval, setBeepInterval] = useState(null)
    const [wakeLock, setWakeLock] = useState(null)

    // Request wake lock when component mounts
    useEffect(() => {
        const requestWakeLock = async () => {
            try {
                if ('wakeLock' in navigator) {
                    const lock = await navigator.wakeLock.request('screen');
                    setWakeLock(lock);
                    console.log('Wake Lock is active in CheckIn');
                }
            } catch (err) {
                console.log(`Wake Lock error in CheckIn: ${err.name}, ${err.message}`);
            }
        };

        requestWakeLock();

        // Handle visibility change
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                await requestWakeLock();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            // Release wake lock and cleanup when component unmounts
            if (wakeLock) {
                wakeLock.release().catch(err => {
                    console.log(`Wake Lock release error: ${err.name}, ${err.message}`);
                });
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const createBeep = useCallback(() => {
        const oscillator = audioContext.createOscillator()
        const gainNode = audioContext.createGain()

        oscillator.type = 'sine'
        oscillator.frequency.setValueAtTime(800, audioContext.currentTime)

        gainNode.gain.setValueAtTime(0, audioContext.currentTime)
        gainNode.gain.linearRampToValueAtTime(0.5, audioContext.currentTime + 0.01)
        gainNode.gain.linearRampToValueAtTime(0, audioContext.currentTime + 0.2)

        oscillator.connect(gainNode)
        gainNode.connect(audioContext.destination)

        oscillator.start(audioContext.currentTime)
        oscillator.stop(audioContext.currentTime + 0.2)
    }, [audioContext])
    
    useEffect(() => {
        const interval = setInterval(() => {
            createBeep()
        }, 1000)
        setBeepInterval(interval)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [createBeep])

    const stopBeeping = () => {
        if (beepInterval) {
            clearInterval(beepInterval)
            setBeepInterval(null)
        }
    }

    const handleContinue = () => {
        stopBeeping()
        onContinue()
    }

    const handleTaskComplete = () => {
        stopBeeping()
        onComplete()
    }

    const handleDistraction = (distractionType) => {
        stopBeeping()
        onDistraction(distractionType)
    }

    return (
        <div className="dialog-overlay">
            <div className="dialog-content checkin-dialog">
                <h2>Time's Up!</h2>
                <p>Did you focus on "{task?.name}"?</p>
                
                <div className="checkin-options">
                    <div className="positive-options">
                        <button 
                            className="continue-button"
                            onClick={handleContinue}
                        >
                            Yes, and I'm still working on it
                        </button>
                        <button 
                            className="complete-button"
                            onClick={handleTaskComplete}
                        >
                            Yes, and I completed it
                        </button>
                    </div>

                    <div className="distraction-section">
                        <h3>No, I got distracted by:</h3>
                        <div className="distraction-buttons">
                            {DISTRACTIONS.map(distraction => (
                                <button
                                    key={distraction.id}
                                    className="distraction-button"
                                    onClick={() => handleDistraction(distraction.name)}
                                >
                                    {distraction.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckIn 