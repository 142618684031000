import { useState } from 'react';
import { useTaskContext } from '../hooks/useTaskContext';
import { useAuthContext } from '../hooks/useAuthContext';

const TaskEditDialog = ({ task, isOpen, onClose, onTaskUpdate }) => {
    const { dispatch } = useTaskContext();
    const { user } = useAuthContext();
    const [formData, setFormData] = useState({
        name: task?.name || '',
        estimatedTime: task?.estimatedTime || 0,
        actualTime: task?.actualTime || 0,
        status: task?.status || 'todo'
    });
    const [error, setError] = useState(null);

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'estimatedTime' || name === 'actualTime' ? parseInt(value) || 0 : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        try {
            // Convert form data to proper types
            const updatedTask = {
                ...task,
                ...formData,
                estimatedTime: parseInt(formData.estimatedTime) || 0,
                actualTime: parseInt(formData.actualTime) || 0
            }
            
            // Update backend first
            const response = await fetch(`/api/tasks/${task._id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify(updatedTask)
            });

            const json = await response.json();

            if (!response.ok) {
                setError(json.error);
                console.error('Failed to update task')
                return;
            }

            // Update both the task context and the block tasks
            dispatch({ type: 'UPDATE_TASK', payload: json })
            if (onTaskUpdate) {
                onTaskUpdate(json)
            }
            onClose()
        } catch (error) {
            setError('Failed to update task');
            console.error('Error updating task:', error)
        }
    };

    return (
        <div className="dialog-overlay">
            <div className="dialog-content">
                <h2>Edit Task</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Task Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    
                    <div className="form-group">
                        <label>Estimated Time (minutes):</label>
                        <input
                            type="number"
                            name="estimatedTime"
                            value={formData.estimatedTime}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Actual Time (minutes):</label>
                        <input
                            type="number"
                            name="actualTime"
                            value={formData.actualTime}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Status:</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                        >
                            <option value="todo">To Do</option>
                            <option value="in_progress">In Progress</option>
                            <option value="done">Done</option>
                        </select>
                    </div>

                    {error && <div className="error">{error}</div>}

                    <div className="dialog-buttons">
                        <button type="submit">Save</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TaskEditDialog; 